import React, {useState, useEffect} from 'react';
import styles from './OrderDetails.module.css';
import { Button, Modal } from 'react-bootstrap';
import axios from 'axios';
import { URL_LIST, DOMAIN_LIST } from '../../../constants/apiList';
import { STATIC_URL_KEYS, getStaticUrl, getAlternateStaticUrl } from '../../../constants/brandConfig';
import { toast } from 'react-toastify';
import Loader from 'react-loader-spinner';
import Table from '../../common/Tables/Table';
import { GoChevronDown, GoChevronUp} from 'react-icons/go';
import moment from 'moment';
import OrderPrescriptions from './Components/OrderPrescriptions';
import OrderNotes from './Components/OrderNotes';
import OrderStatistics from './Components/OrderStatistics';
import UpdateOrderStatus from './Components/UpdateOrderStatus';
import { getParsedOrderId, getUnpaddedValue } from '../../../helpers';
import CancelOrder from './Components/CancelOrder';

function OrderDetails(props) {
  const [modalContentType, setModalContentType] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [updateLoader, setUpdateLoader] = useState(false);
  const [rows, setRows] = useState([]);
  const [orderDetails, setOrderDetails] = useState({});
  const [show, setShow] = useState(false);
  const [prescriptionRows, setPrescriptionRows] = useState([]);
  const [orderNotes, setOrderNotes] = useState([]);
  const [shipmentData, setShipmentData] = useState([]);
  const [updateStatusOrderId, setUpdateStatusOrderId] = useState(0);
  const [statusToUpdate, setStatusToUpdate] = useState("");
  const [reasonOfUpdate, setReasonOfUpdate] = useState("");
  const [cancelReason, setCancelReason] = useState("");
  const [cancelButtonText, setCancelButtonText] = useState("");
  const columns = [
    { title: "S No", field: "sno" },
    { title: "Date", field: "date" },
    { title: "Order ID", field: "orderId" },    
    { title: "Status", field: "status" },
    { title: "Show Details", field: "showDetails" },
    { title: "Update Status", field: "updateStatus" },
    { title: "View Prescription", field: "viewPrescription" },
    { title: "View Notes", field: "viewNotes" },
    { title: "Platform", field: "platform" },
  ];
  const MODAL_CONTENT_TYPES = {
    ORDER_STATISTICS: "ORDER_STATISTICS",
    ORDER_PRESCRIPTION: "ORDER_PRESCRIPTION",
    ORDER_NOTES: "ORDER_NOTES",
    UPDATE_ORDER_STATUS: "UPDATE_ORDER_STATUS",
    CANCEL_ORDER_CONFIRM: "CANCEL_ORDER_CONFIRM",
    SHIPMENT_DATA: "SHIPMENT_DATA"
  };


  const cancelClickHandler = () =>{
    const requestData = {
      altUrl: getStaticUrl(STATIC_URL_KEYS.CANCEL_AND_REFUND),
      url: getAlternateStaticUrl(STATIC_URL_KEYS.CANCEL_AND_REFUND),
      requestBody: {incrementId: orderDetails.order_id, cashFree:true, cancelReason:`Cancellation Reason: ${cancelReason}`}
    }
    axios.post(URL_LIST.SEND_POST_REQUEST,requestData)
    .then(res=>{
      console.log(res);
      setShowModal(false);
      if(res.data.status==='success'){
        toast.success(`Order (${orderDetails.order_id}) Cancelled Successfully`);
      }
      props.refreshCustomerDetails();
    })
    .catch(err=>{
      console.log("Error in cancel order", err);
      toast.error("Error in cancel order", err.message);
    })
  }

  useEffect(()=>{
    const isCOD = orderDetails?.payment_method === 'cod';
    const text = isCOD?`Cancel Order`:`Cancel Order and Process Refund of ₹${orderDetails.order_total}`;
    setCancelButtonText(text)
  },[orderDetails])

  const generateRows = (orders) => {
    const finalRows = [];
    let index = 1;
    for(const order of orders) {
      const sno = index;
      index++;
      const finalRow = {sno};
      const orderDate = order.date_created && order.date_created.date || "";
      finalRow.date = moment(orderDate).format("DD MMM YYYY");
      finalRow.orderId = order.order_id;
      finalRow.status = order.status;
      finalRow.showDetails = <Button className="regular-button" size="sm" onClick={evt=>searchOrder(order)}>Show More</Button>
      finalRow.updateStatus = <Button variant="dark" size="sm" onClick={evt=>onUpdateStatusClick(order)}>Update Status</Button>
      finalRow.viewPrescription = <Button variant="dark" size="sm" onClick={evt=>onViewPrescriptionClick(order)}>Prescription</Button>
      finalRow.viewNotes = <Button variant="info" size="sm" onClick={evt=>onViewNotesClick(order)}>Notes</Button>
      finalRow.platform = order.platform;
      finalRows.push(finalRow);
    }
    setRows(finalRows);
  }

  useEffect(()=>{
    generateRows(props.orders);
  },[props.orders]);
  const prepareRows = (data) => {
    const tempRows = [];
    for(const productData of data) {
      const finalRow = {};
      finalRow.product = productData.name;
      finalRow.quantity = productData.quantity;
      finalRow.price = <span style={{display: "inline-flex"}}>{productData.subtotal?productData.subtotal:"NA"} <s style={{paddingLeft: "10px", fontSize: "12px"}}>{productData?.mrp}</s></span>;
      tempRows.push(finalRow);
    }
    return tempRows;
  }

  const onViewNotesClick = async (order) => {
    setLoader(true);
    setShowModal(true);
    setModalContentType(MODAL_CONTENT_TYPES.ORDER_NOTES);
    const requestData = {
      order_id: getParsedOrderId(order.order_id), 
    }
    const options = {
      headers: {
        brand: "MM"
      }
    }
    await axios.post(URL_LIST.NOTES, requestData, options)
    .then(res=>{
      if(res.data.orderData.success) {
        const orderNotesFinal = res.data.orderData.result.map((comment,index)=>{
          return {
            sno: index+1,
            note: comment.comment_content,
            date: moment(comment.comment_date).format("D MMM YYYY hh:mm A"),
          }
        })
        setOrderNotes(orderNotesFinal);
      }
      else {
        toast.error("Unable to Fetch Order Notes");
      }
      if(res.data.shipmentData.success) {
        const orderNotesFinal = res.data.shipmentData.result.map((comment,index)=>{
          return {
            sno: index+1,
            shipment: comment.description,
            date: moment(comment.created_at).format("D MMM YYYY hh:mm A"),
          }
        })
        setShipmentData(orderNotesFinal);
      }
      else {
        toast.error("Unable to Fetch Shipment Data");
      }
      setLoader(false);
    })
    .catch(err=>{
      toast.error("Unable to Make call to fetch order notes.");
      setLoader(false);
      console.log("ERROR IN /updateOrderStatus", err);
    });
  }

  const onViewPrescriptionClick = async (order) => {
    setLoader(true);
    setShowModal(true);
    setModalContentType(MODAL_CONTENT_TYPES.ORDER_PRESCRIPTION);
    const requestData = {
      orderId: getUnpaddedValue(order.order_id), 
    }
    await axios.post(URL_LIST.GET_PRESCRIPTION_BY_ORDER_ID, requestData)
    .then(res=>{
      console.log("GET PRESCRIPTION RES",res);
      if(res.data.success) {
        setPrescriptionRows(res.data.results);
      }
      else {
        toast.error(res.data.message);
        setShowModal(false);
      }
      setLoader(false);
    })
    .catch(err=>{
      toast.error("Unable to Make call to fetch order prescription");
      setLoader(false);
      console.log("ERROR IN /updateOrderStatus", err);
    });    
  }

  const onUpdateStatusClick = (order) => {
    setUpdateStatusOrderId(order.order_id);
    setShowModal(true);
    setModalContentType(MODAL_CONTENT_TYPES.UPDATE_ORDER_STATUS);
    setStatusToUpdate(order.status);
  }

  const updateOrderStatus = async (evt) => {
    setUpdateLoader(true);
    const requestData = {
      orderId: updateStatusOrderId, 
      status: statusToUpdate,
      reason: reasonOfUpdate,
    }
    await axios.post(URL_LIST.UPDATE_ORDER_STATUS, requestData)
    .then(res=>{
      if(res.data.success) {
        toast.success(res.data.message);
        setShowModal(false);
        props.refreshCustomerDetails();
      }
      else {
        toast.error(res.data.message);
      }
      setUpdateLoader(false);
    })
    .catch(err=>{
      toast.error("Unable to Make call for update order status");
      setUpdateLoader(false);
      console.log("ERROR IN /updateOrderStatus", err);
    });
  }

  const searchOrder = async (order) => {
    setLoader(true);
    setShowModal(true);
    setModalContentType(MODAL_CONTENT_TYPES.ORDER_STATISTICS);
    setOrderDetails({});
    const requestData = {
      order_id: getParsedOrderId(order.order_id),
    }
    axios.post(URL_LIST.ORDER, requestData)
    .then(res=>{
      if(res.data.status) {
        const tempOrderDetails = {...res.data.result};
        tempOrderDetails.tableColumns = [
          { title: "Product", field: "product", headStyle: (cellData, index)=>{ return { minWidth: "400px"};}},
          { title: "Quantity", field: "quantity", number: true},
          { title: "Price", field: "price", number: true},          
        ];
        tempOrderDetails.tableRows = prepareRows(res.data.result.products);
        setOrderDetails(tempOrderDetails);
        setLoader(false);
    }
      else {
        toast.error(res.data.message);
        setShowModal(false);
        setLoader(false);
      }
    })
    .catch(err=>{
      setLoader(false);
      toast.error("Unable to search customer details");
      console.log("ERROR IN /searchCustomer", err);
    })
  }
  const renderModalContent = () => {
    const { ORDER_NOTES, ORDER_PRESCRIPTION, ORDER_STATISTICS, UPDATE_ORDER_STATUS, CANCEL_ORDER_CONFIRM, SHIPMENT_DATA} = MODAL_CONTENT_TYPES;
    switch(modalContentType) {
      case ORDER_STATISTICS: 
        return <OrderStatistics orderDetails={orderDetails} setShowModal={setShowModal} setModalContentType={setModalContentType} cancelConfirmModal={MODAL_CONTENT_TYPES.CANCEL_ORDER_CONFIRM} cancelButtonText={cancelButtonText}/>
      case ORDER_PRESCRIPTION:
        return <OrderPrescriptions rows={prescriptionRows} />
      case ORDER_NOTES: 
        return <OrderNotes orderNotes={orderNotes} shipmentData={shipmentData} />
      case UPDATE_ORDER_STATUS: 
        return <UpdateOrderStatus {... {orderId: updateStatusOrderId, statusToUpdate, setStatusToUpdate, reasonOfUpdate, setReasonOfUpdate, loader: updateLoader, updateOrderStatus} } />
      case CANCEL_ORDER_CONFIRM:
        return <CancelOrder setShowModal={setShowModal} cancelReason={cancelReason} setCancelReason={setCancelReason}/>
      default: 
        return <></>;
    }
  }
  return (
    <div className={styles["order-details"]}>
      <div style={{display: "flex", marginBottom: "10px",}}>
        <div className="page-heading" style={{minWidth: "200px"}}>
        Order Details
        </div>
        {
          show?
          <div className={styles["toggle-button"]} onClick={evt=>setShow(false)}>
            HIDE <GoChevronUp style={{width: "20px", height: "20px"}} />
          </div>
          :
          <div className={styles["toggle-button"]} onClick={evt=>setShow(true)}>
            SHOW <GoChevronDown style={{width: "20px", height: "20px"}} />
          </div>
        }
      </div>
      {
        show?
        <div className={styles["main-content"]}>
          <Table title="Orders" columns={columns} rows={rows} search={true} pageSize={rows.length>10?10:rows.length}/>
        </div>
        :
        null
      }

      <Modal
        centered
        show={showModal}
        close={true}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {
            loader?
            <Loader type="ThreeDots" width={30} height={30} color="#144166" />
            :
            <div className={styles["info-card"]}>
              {
                renderModalContent()
              }
              <div className={styles["row"]}>
                {
                  modalContentType===MODAL_CONTENT_TYPES.CANCEL_ORDER_CONFIRM?
                    <Button
                    className="regular-button"
                    style={{ marginTop: "20px", width: "100%"}}
                    onClick={cancelClickHandler}
                    disabled={!cancelReason}
                  >
                    {cancelButtonText}
                  </Button>
                :
                  <Button
                    className="regular-button"
                    style={{ marginTop: "20px", width: "100%"}}
                    onClick={() => {
                      setShowModal(false);
                    }}
                  >
                    Close
                  </Button>
                }
              </div>
            </div>
          }            
        </div>
      </Modal> 
    </div>    
  )
}
export default OrderDetails;